import React, { Component } from 'react';
// import libraries
import AOS from 'aos';
import 'aos/dist/aos.css';
// import components
import MoRouter from './MoRouter';
import { ThemeProvider } from 'styled-components';
import { createTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { breakpointsMaterialUi } from './themes/breakpoints.theme';
import MoTheme from 'themes/mo.theme';

window.React = React;

// Override material-ui Grid breakpoints
const MoBreakPointsOverride = createTheme({
  breakpoints : {
    values: breakpointsMaterialUi
  }
});

class App extends Component {
  
  render() { 
    return ( 
      <React.Fragment>
        <ThemeProvider theme={MoTheme}>
          <MuiThemeProvider theme={MoBreakPointsOverride}>
            <MoRouter/>
          </MuiThemeProvider>
        </ThemeProvider>
      </React.Fragment>
    );
  }

  componentDidMount() {
    AOS.init();
  }
}
 
export default App;

