import React, {useState} from 'react';
import { Button, Dropdown, Option, Separator } from './DropdownButton.style';

const DropdownButton = ({text, options}) => {
    const [ onHover, setOnHover ] = useState(false);
    return (
        <Button onMouseEnter={() => setOnHover(true)} onMouseLeave={() => setOnHover(false)}>
            {text}<i className={`fas fa-chevron-${onHover ? 'up' : 'down'}`} />
            <Dropdown isVisible={onHover}>
                {options && options.map((option, i) =>
                    <React.Fragment key={i}>
                        <Option href={option.url}>{option.text}<i/></Option>
                        {(i === 0) && <Separator/>}
                    </React.Fragment>
                )}
            </Dropdown>
        </Button>
    );
}
 
export default DropdownButton;