import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useComponentVisible } from 'components/utils';
import { Container, PrimaryBtn, Nav, Menu, MenuItem, SubMenu, SubContainer, SubHeading, Category, CatHeading, CatList, CatItem, StorySection, StoryItem, Back, Icon } from './SideNav.style';

const SideNav = ({isVisible, cms, nav, loggedIn, headerHeight, onClose}) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [activeSub, setActiveSub] = useState();
    
    function onSelectSection(menu) {
        setActiveSub(menu);
        setIsComponentVisible(true)
    }

    function onSelectItem() {
        setIsComponentVisible(false);
        onClose();
    }
    
    return (  
        <Container isVisible={isVisible} isSubActive={isComponentVisible} headerHeight={headerHeight}>
            <Nav>
                {loggedIn && <PrimaryBtn>{cms.snippets['text-common-donate'].text}</PrimaryBtn>}
                <Menu>
                    {(typeof nav === 'object') && Object.keys(nav).map(menu => 
                        <React.Fragment key={menu}>
                           <MenuItem onClick={() => onSelectSection(menu)}>{nav[menu].title}<i className="fas fa-chevron-right"/></MenuItem>
                        </React.Fragment>
                    )}
                </Menu>
                
                <SubMenu ref={ref} isVisible={isComponentVisible}>
                    { activeSub &&
                        <SubContainer>
                            <SubHeading>{nav[activeSub].title}</SubHeading>
                            {activeSub !== 'stories' && nav[activeSub].categories.map((category, i) =>
                                <React.Fragment key={i}>
                                    {category.navItems.length > 0 &&
                                        <Category>
                                            <CatHeading>{category.text}</CatHeading>
                                            <CatList>
                                                {category.navItems.map((item, j) =>
                                                    <CatItem key={j} href={item.url}>{item.title}<i className="fas fa-chevron-right"/></CatItem>
                                                )}
                                            </CatList>
                                        </Category>
                                    } 
                                </React.Fragment>
                            )}
                            {activeSub === 'stories' && 
                                <CatList>
                                    <StorySection>
                                        {nav[activeSub].primary.map((item,i) =>
                                            <StoryItem key={i} isPrimary={true} onClick={onSelectItem}>
                                                <Link to={item.url}><span>{item.title}</span></Link>
                                            </StoryItem>
                                        )}
                                    </StorySection>
                                    <StorySection>
                                        {nav[activeSub].secondary.map((item,i) =>
                                            <StoryItem key={i} onClick={onSelectItem}>
                                                <StoryItem to={item.url}><span>{item.title}</span></StoryItem>
                                            </StoryItem>
                                        )}
                                    </StorySection>
                                </CatList>
                            }
                            <Back>
                                <Icon onClick={() => setIsComponentVisible(false)}><i className="fas fa-chevron-left"/>{cms.snippets['text-common-back'].text}</Icon>
                            </Back>
                            
                        </SubContainer>
                    }
                </SubMenu>
            </Nav>
        </Container>
    );
}
 
export default SideNav;
