import { useEffect, useState } from 'react';
import { Container, Content, ImageDesktop, ImageMobile, ImageCon, MobileImageCon,  Description, Form, InputGroup, Label, CheckboxWrapper, ConsentGroup, Consent, Error, ButtonWrapper } from './DataForm.style';
import { Heading, Input, Button } from 'components/common';
import { renderParagraph, renderImage } from 'components/utils/render';
import MoOverlay from 'components/common/Overlay/Overlay';
import Success from './Success';
import { getEnvironment, isValidEmailAddress, getCurrentLocaleString } from 'components/utils';
import apiEndpoints from 'components/utils/apiEndpoints';

declare global {
    interface Window {
        dataLayer: any;
    }
}

interface Props {
    cms: any
    image: any;
    heading: string;
    description: string;
    formFields: Array<{label: string, error: string, field: string, required: boolean}>;
    consent: string;
    btnText: string;
    successOverlay: {image: string, heading: string, description: string, btnText: string}
    successImage: string;
    successHeading: string;
    successDescription: string;
    successBtnText: string;
    subscriptions: string;
    source: string,
    formType: string,
    expressionOfInterestName: string,
    mobileImage: string,
    isInMultiColumn?: boolean
}

const DataForm: React.FC<Props> = ({ cms, image, heading, description, formFields, consent, btnText, successOverlay, subscriptions, source,formType, expressionOfInterestName,mobileImage, isInMultiColumn}) => {
    const [consentInput, setConsentInput] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [showDone, setShowDone] = useState(false);
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [fields, setFields] = useState<{label: string; value: string; error: string; field: string; required: boolean; valid: boolean}[]>([]);
    const [requestFailed, setRequestFailed] = useState(false);


    useEffect(() => {
        resetFields();
        // eslint-disable-next-line
    },[])


    useEffect(() => {
        if (isFormDirty) validateForm();
        // eslint-disable-next-line
    }, [fields, consentInput])


    const onChangeInput = (index: number, value: string) => {
        let updateFields = [...fields];
        updateFields[index].value = value;
        updateFields[index].valid = !formFields[index].required || (formFields[index].required && validateByFieldType(value, formFields[index].field));
        setFields(updateFields);
    }


    const getFieldValidation = (index: number) => {
        if (!isFormDirty || !fields[index].required) return true;
        return validateByFieldType(fields[index].value, fields[index].field);
    }
    

    const validateByFieldType = (value: string, type: string) => {
        switch(type.toLocaleLowerCase()){
            case 'email':
                return value !== '' && isValidEmailAddress(value);
            default:
                return value !== '';
        }
    }


    const onClickButton = () => {
        setIsFormDirty(true);

        if(validateForm()){
            let request: any = {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(formDataRequest())
            };
        
            fetch(apiEndpoints.SF_data_subscription[getEnvironment()], request)
                .then((res) => {
                    return res.json();
                })
                .then(
                    (result) => {
                        if (result.length > 0 && result[0] && result[0].success === true) {
                            setShowDone(true);
                            setRequestFailed(false);

                            // Trigger dataLayer event
                            let dataLayer = window?.dataLayer; 
                            if (dataLayer && typeof dataLayer.push === 'function') {
                                dataLayer.push({
                                    event: 'submit_form',
                                    form_id: expressionOfInterestName || window.location.pathname,
                                    form_name: expressionOfInterestName || window.location.pathname,
                                    location: window.location.href,
                                    step_label: 'start',
                                    step_number: '1'
                                });
                            }
                        } else {
                            setRequestFailed(true);
                            console.log(result);
                        }
                    },
                    (error) => {
                        setRequestFailed(true);
                        console.log("Request error " + error);
                    }
                );
        }
    }


    const formDataRequest = () => {
        let firstName = fields.find(ele => ele.field === 'firstName'),
        lastName = fields.find(ele => ele.field === 'lastName'),
        email = fields.find(ele => ele.field === 'email');
       
        let extraData: {[k: string]: any} = {};

        let subsData:{[k: string]: any}[] = [];
        let remainingFields = fields.filter(ele => ['firstName', 'lastName', 'email','expressionOfInterestName'].indexOf(ele.field) < 0);
        remainingFields.forEach(ele => extraData[ele.field] = ele.value);

        var payload = {
            firstName: firstName?.value,
            lastName: lastName?.value,
            email: email?.value,
            extraFields: extraData,
            subscriptions: subsData,
            locale: getCurrentLocaleString("_")
        }
        let subsArray = null;


        if (formType === 'expressionOfInterest'){
            payload.extraFields["expression_of_interest"] = expressionOfInterestName;
        }

        if (subscriptions && source) {
            subsArray = subscriptions.split(',');
            subsArray.forEach(ele => subsData.push({
                [ele]: {
                    subscribed: true,
                    source: source
                }
            }));
            payload.subscriptions = subsData;
        }

        return payload;
    }


    const validateForm = () => {
        let hasError = false;

        if(!consentInput) hasError = true;

        for (let i = 0; i < fields.length; i++){
            if (fields[i].valid === false) hasError = true;
        }

        setHasError(hasError);
        return !hasError;
    }


    const onOverlayClose = () => {
        
        // Reset Form
        resetFields();
        setConsentInput(false);
        setHasError(false);
        setIsFormDirty(false);

        setShowDone(!showDone);
    }


    const resetFields = () => {
        let initFields: {label: string; value: string; error: string; field: string; required: boolean; valid: boolean}[] = [];

        formFields.forEach(field => {
            initFields.push({
                label: field.label,
                value: '',
                error: field.error,
                field: field.field,
                required: field.required,
                valid: !field.required
            })
        }); 
        setFields(initFields);
    }


    return (
        <Container>
            {showDone && <MoOverlay
                content={<Success
                    image={successOverlay.image}
                    heading={successOverlay.heading}
                    description={successOverlay.description}
                    btnText={successOverlay.btnText}
                    onClick={() => onOverlayClose()} />}
                onClick={() => onOverlayClose()} />}
            <Content isInMultiColumn={isInMultiColumn}>
                { image && Object.keys(image).length > 0 && <ImageDesktop><ImageCon>{renderImage(image)}</ImageCon></ImageDesktop> }
                { (heading || (description && description.length > 0)) &&  
                    <Description>
                        <ImageMobile><MobileImageCon>{renderImage(mobileImage)}</MobileImageCon></ImageMobile>
                        <Heading level="h2" isHeading={true}>{heading}</Heading>
                        {renderParagraph(description)}
                    </Description>
                }
                <Form hasError={hasError} isInMultiColumn={isInMultiColumn}
                    hasLeftContent={(image && Object.keys(image).length > 0) || (heading || (description && description.length > 0))}>
                    {fields.map((field, index) =>
                        <InputGroup key={index}>
                            <Label>{field.label}</Label>
                            <Input value={field.value}
                                onChange={e => onChangeInput(index, e.target.value)}
                                placeholder={!getFieldValidation(index) ? field.error : ''}
                                hasError={!getFieldValidation(index)} />
                        </InputGroup>
                    )}
                    <ConsentGroup>
                        <CheckboxWrapper>
                            <Input type="checkbox" checked={consentInput}
                                onChange={e => setConsentInput(e.target.checked)} 
                                hasError={isFormDirty ? !consentInput : false}/>
                        </CheckboxWrapper>
                        <Consent>{renderParagraph(consent)}</Consent>
                    </ConsentGroup>
                    <Error isShown={requestFailed}>{cms?.snippets['text-common-request-failed']?.text}</Error>
                    <ButtonWrapper>
                        <Button btnType='primary' btnText={btnText} onClick={onClickButton} />
                    </ButtonWrapper>
                </Form>
            </Content>
        </Container>
    );
};

export default DataForm;