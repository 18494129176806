import React, { useState } from 'react';
import Slider from 'react-slick';
import { renderImage } from 'components/utils';
import { Container, Slide, ArrowsContainer, StepIndicator, Arrow, Author, Image, ImageContainer, Info, Body, Content,
    Description, CTAContainer, CTA, Profile, Title } from './SocialProof.style';

/**
 * 
 * @param slides : array of slides
 * @param noSideImage : true If there is no side image for this carousel
 * 
 */
const SocialProof = ({slides, noSideImage}) => {
   const [thisSlider,  setThisSlider] = useState();
   const [currentSlide, setCurrentSlide] = useState(0);
   const [isNavigating, setIsNavigating] = useState(false);
   const settings = {
       infinite: false,
       arrows: false,
       fade: true,
       afterChange: state => {
           setCurrentSlide(state);
       }
   };

   function renderDescription (slide) {
       if(slide.description && slide.name) {
           let boldIndex = slide.description.trim().toLowerCase().indexOf(slide.name.trim().toLowerCase());
           if(boldIndex >= 0) {
               return <React.Fragment>
                   {slide.description.substring(0, boldIndex)}
                   <strong>{slide.description.substring(boldIndex, boldIndex + slide.name.length)}</strong>
                   {slide.description.substring(boldIndex + slide.name.length)}
               </React.Fragment>
           }
       }
       return slide.description;
   }

   function handleArrowClick(isPrev){
       if(thisSlider && !isNavigating){
           setIsNavigating(true);
           if(isPrev){
               (currentSlide > 0) ? thisSlider.slickPrev() : thisSlider.slickGoTo(slides.length - 1);
           } else {
               (currentSlide < slides.length - 1) ?  thisSlider.slickNext() :  thisSlider.slickGoTo(0);
           }
           setTimeout(() => setIsNavigating(false), 500);
       }
   }

   return (
       <Container>
           <Slider ref={slider => (setThisSlider(slider))} {...settings}>
               {slides.map((slide, index) =>
                   <div key={index}>
                       <Slide>
                           <Body>
                               <CTAContainer isCta={slides[currentSlide] && slides[currentSlide].cta && slides[currentSlide].cta.trim()}>
                                   {(slides[currentSlide] && slides[currentSlide].cta && slides[currentSlide].cta.trim()) &&
                                       <CTA href={slides[currentSlide].link ? slides[currentSlide].link : null}>
                                           {slides[currentSlide].cta} <span/>
                                       </CTA>
                                   }
                                   <ArrowsContainer>
                                       <Arrow  direction='left' onClick={() => handleArrowClick(true)} />
                                       <StepIndicator>{`${(currentSlide + 1)} OF ${slides.length}`}</StepIndicator>
                                       <Arrow  onClick={() => handleArrowClick()}/>
                                   </ArrowsContainer>
                               </CTAContainer>
                               <Content>
                                   <Description>{renderDescription(slide)}</Description>
                                   { noSideImage &&
                                       <Author href={slide.link ? slide.link : null}>
                                           <Image>
                                               {slide.image ?
                                                renderImage(slide.image)
                                               : <span>{ slide.name ? slide.name.substring(0,1).toUpperCase() : ''}</span>
                                               }
                                           </Image>
                                           <Info>
                                               <strong>{slide.name}</strong>
                                               <div>{slide.title}</div>
                                           </Info>
                                       </Author>
                                   }
                               </Content>
                           </Body>
                           { slide.image &&
                               <Profile href={slide.link ? slide.link : null}> 
                                   <Title>
                                       <strong>{slide.name}</strong>
                                       <div>{slide.title}</div>
                                   </Title>
                                   <ImageContainer>{renderImage(slide.image)}</ImageContainer>
                               </Profile>
                           }
                       </Slide>
                   </div>
               )}
           </Slider>
       </Container>
   );
}

export default SocialProof;
