import AccordionItem from './AccordionItem';
import { Container } from 'themes/MoGrid';
import { Wrapper, Heading, Body } from './Accordion.style';

interface Props {
    title: string;
    items: any[]
}

const Accordion: React.FC<Props> = ({ title, items }) => {
    return (
        <Container>
            <Wrapper>
                <Heading>{title}</Heading>
                <Body>
                    {items.map((item, i) => 
                    <AccordionItem key={i} index={i} subHeading={item.subheading} content={item.content}/>
                    )}
                </Body>
            </Wrapper>
        </Container>
    );
};

export default Accordion;