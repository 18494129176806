
import React from 'react';
import { Container, Row, Col } from 'themes/MoGrid';
import { Wrapper, PartnerContainer, PartnerRow, TitleUnderline, LogoContainer, StyledImg, PartnerLink, PartnersCopy} from './partners.style';
import { getImage, renderParagraph } from 'components/utils';
import { Link } from 'react-router-dom';



const DollarMatching = ({ cms }) => {

   const pageTitle = cms.partnersSettings.dmPageTitle
   const pageDescription = cms.partnersSettings.dmPageDescription 
   const t1Title = cms.partnersSettings.dmT1Title
   const t2Title = cms.partnersSettings.dmT2Title
   const t3Title = cms.partnersSettings.dmT3Title
   const t4Title = cms.partnersSettings.dmT4Title

   const dollarMatchers = cms.partners.filter( partner=> partner.type === 'dollar_matcher' )[0]

   const renderDMs = (dollarMatchers) =>{

     
      
      const t1 = dollarMatchers.items.filter(partner => partner.partnerType === 't1')
      const t2 = dollarMatchers.items.filter(partner => partner.partnerType === 't2')
      const t3 = dollarMatchers.items.filter(partner => partner.partnerType === 't3')
      const t4 = dollarMatchers.items.filter(partner => partner.partnerType === 't4')
      const dmRows = []

      if(t1.length > 0){
         dmRows.push(
            <PartnerRow>
               <TitleUnderline>{t1Title}</TitleUnderline>
               <Row>
               {t1.map((item, index) => 
                  <Col sm={3} xs={6} key={index}>
                        <LogoContainer>
                           <PartnerLink href={item.link ? item.link : "#"} target={"_blank"} rel="noreferrer" isActive={true}>
                           <StyledImg isActive={true} src={getImage(item.logo, 'small')} name={item.name} alt={item.name}></StyledImg>
                           </PartnerLink>
                        </LogoContainer>
                  </Col>
               )}
               </Row>
            </PartnerRow>
         )
      }
      if(t2.length > 0){
         dmRows.push(
            <PartnerRow>
               <TitleUnderline>{t2Title}</TitleUnderline>
               <Row>
               {t2.map((item, index) => 
                  <Col sm={3} xs={6} key={index}>
                        <LogoContainer>
                           <PartnerLink href={item.link ? item.link : "#"} target={"_blank"} rel="noreferrer" isActive={true}>
                           <StyledImg isActive={true} src={getImage(item.logo, 'small')} name={item.name} alt={item.name}></StyledImg>
                           </PartnerLink>
                        </LogoContainer>
                  </Col>
               )}
               </Row>
            </PartnerRow>
         )
      }
      if(t3.length > 0){
         dmRows.push(
            <PartnerRow>
               <TitleUnderline>{t3Title}</TitleUnderline>
               <Row>
               {t3.map((item, index) => 
                  <Col sm={3} xs={6} key={index}>
                        <LogoContainer>
                           <PartnerLink href={item.link ? item.link : "#"} target={"_blank"} rel="noreferrer" isActive={true}>
                           <StyledImg isActive={true} src={getImage(item.logo, 'small')} name={item.name} alt={item.name}></StyledImg>
                           </PartnerLink>
                        </LogoContainer>
                  </Col>
               )}
               </Row>
            </PartnerRow>
         )
      }
      if(t4.length > 0){
         dmRows.push(
            <PartnerRow>
               <TitleUnderline>{t4Title}</TitleUnderline>
               <Row>
               {t4.map((item, index) => 
                  <Col sm={3} xs={6} key={index}>
                        <LogoContainer>
                           <PartnerLink href={item.link ? item.link : "#"} target={"_blank"} rel="noreferrer" isActive={true}>
                           <StyledImg isActive={true} src={getImage(item.logo, 'small')} name={item.name} alt={item.name}></StyledImg>
                           </PartnerLink>
                        </LogoContainer>
                  </Col>
               )}
               </Row>
            </PartnerRow>
         )
      }

      return dmRows

   }

   return (
         <Wrapper>
            <PartnerContainer>
                   <Container>
                   <PartnersCopy>
                     <h1>{pageTitle}</h1>
                     <p>{renderParagraph(pageDescription)}</p>
                     <Link to='/friends/'>{cms.partnersSettings.partnersPageTitle}</Link>
                   </PartnersCopy>
                    {renderDMs(dollarMatchers)}
                  </Container> 
            </PartnerContainer>
        </Wrapper>
   );
}
 
export default DollarMatching;
