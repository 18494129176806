import React, { useState, useEffect, useContext } from 'react';
import Slider from "react-slick";
import { renderImage } from 'components/utils';
import { CMSContext } from 'context/AppContext';
import { Container, Slide, MediumNav, OtherNav, ImageContainer, Image, Content, Detail, Title, Description, Navigation, CTA, ArrowsContainer, Arrow, StepIndicator } from './AutoplayCarousel.style';

/**
 * 
 * @param slides : array of slides
 * 
 */
const AutoplayCarousel = ({slides}) => {
    const [cms] = useState(useContext(CMSContext));
    const [currentSlide, setCurrentSlide] = useState(0);
    const [isNavigatingNext, setIsNavigatingNext] = useState(true);
    const [onHover, setOnHover] = useState(false);
    const [autoplay, setAutoplay] = useState(true);

    let mySlider = null;
    const settings = {
        infinite: true,
        arrows: false,
        fade: true,
        autoplay: false,
        speed: 200,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        beforeChange: () => {
            //setIsNavigatingNext(false);
        },
        afterChange: state => {
            setCurrentSlide(state);
            autoplay ? setIsNavigatingNext(true) : setIsNavigatingNext(false);
        }
    };

    //Include a timer to handel the autoplay
    useEffect(() => { 
        let interval = null;
        if(autoplay){
            //Implementing the setInterval method 
            interval = setInterval(() => { 
                intervalCall();
            }, settings.autoplaySpeed); 
        }else{
            (interval) && clearInterval(interval);
        }
        //Clearing the interval 
        return () => interval && clearInterval(interval); 
         // eslint-disable-next-line
    }, [autoplay]);


    //on arrow progress animation stop navigate to next slide
    useEffect(() => {
        if(!isNavigatingNext){
            navigateSlide(false);
        }
         // eslint-disable-next-line
    },[isNavigatingNext]);

    //set arrow progress animation to false (stop) and navigate to next slide 
    function intervalCall() {
        setIsNavigatingNext(false);
    }

    useEffect(() => {
        mySlider.slickPlay();
    },[mySlider]);

    function handleArrowClick(isPrev){
        mySlider.slickPause();
        setIsNavigatingNext(false);
        setAutoplay(false);

        navigateSlide(isPrev);
    }

    function navigateSlide(isAnimateToLeft) {
        if(mySlider){
            if(isAnimateToLeft){
                (currentSlide > 0) ? mySlider.slickPrev() : mySlider.slickGoTo(slides.length - 1);
            } else {
                (currentSlide < slides.length - 1) ?  mySlider.slickNext() :  mySlider.slickGoTo(0);
            }
        }
    }

    return ( 
        <Container>
            <Slider ref={slider => (mySlider = slider)} {...settings}>
                {slides.map((slide, index) =>
                    <div key={index}>
                        <Slide>
                            <ImageContainer href={slide.link ? slide.link : null}
                                onMouseEnter={() => setOnHover(true)}
                                onMouseLeave={() => setOnHover(false)}
                                isOnHover={onHover}>
                                <Image>{renderImage(slide.image)}</Image>
                            </ImageContainer>
                            <Content>
                                <Detail>
                                    <Title href={slide.link ? slide.link : null}
                                     onMouseEnter={() => setOnHover(true)}
                                     onMouseLeave={() => setOnHover(false)}
                                     isOnHover={onHover}>
                                        {slide.title}
                                    </Title>
                                    <Description>{slide.description}</Description>
                                </Detail>
                                <OtherNav>
                                    <Navigation>
                                        <CTA href={slide.link ? slide.link : null}>
                                            {(slide.cta && slide.cta.trim()) ? slide.cta.trim() : 'More Detail'} <span/>
                                        </CTA>
                                        <ArrowsContainer>
                                            <Arrow direction='left' onClick={() => handleArrowClick(true)}>
                                                <img src="/resources/images/icons/arrow-black.png" width="14" height="9" alt="arrow-black"/>
                                            </Arrow>
                                            <StepIndicator>{`${(currentSlide + 1)} ${cms.snippets['text-common-of'].text} ${slides.length}`}</StepIndicator>
                                            <Arrow onClick={() => handleArrowClick()} isNavigating={isNavigatingNext}>
                                                <img src="/resources/images/icons/arrow-black.png" width="14" height="9" alt="arrow-black"/>
                                            </Arrow>
                                        </ArrowsContainer>
                                    </Navigation>
                                </OtherNav>
                            </Content>
                        </Slide>
                        <MediumNav>
                            <Navigation>
                                <CTA href={slide.link ? slide.link : null}>
                                    {(slide.cta && slide.cta.trim()) ? slide.cta.trim() : 'More Detail'} <span/>
                                </CTA>
                                <ArrowsContainer>
                                    <Arrow direction='left' onClick={() => handleArrowClick(true)}>
                                        <img src="/resources/images/icons/arrow-black.png" width="14" height="9" alt="arrow-black"/>
                                    </Arrow>
                                    <StepIndicator>{`${(currentSlide + 1)} ${cms.snippets['text-common-of'].text} ${slides.length}`}</StepIndicator>
                                    <Arrow onClick={() => handleArrowClick()} isNavigating={isNavigatingNext}>
                                        <img src="/resources/images/icons/arrow-black.png" width="14" height="9" alt="arrow-black"/>
                                    </Arrow>
                                </ArrowsContainer>
                            </Navigation>
                        </MediumNav>            
                    </div>
                )}
            </Slider>
        </Container>
     );
}
 
export default AutoplayCarousel;