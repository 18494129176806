import React, { useEffect, useContext } from 'react';
import * as Templates from './templates';
import Stories from '../stories/Stories/Stories';
import * as Story from '../stories';
import { useLocation } from 'react-router-dom';
import { Helmet } from "react-helmet";
import ConfigContext from 'context/ConfigContext';
import { checkNested, getImage, getUrlLocale, getUrlFromLocale, hreflangStandardCodes, isHomepage, scrollWithOffset } from 'components/utils';

const DynamicPage = ({ cms, content, page, componentList }) => {
   let location = useLocation();
   let config = useContext(ConfigContext);
   const configCountry = config.getCountry();
   const configLanguage = config.getLanguage();

   useEffect(() => {
      // Set title tag by page title on location changed.
      document.title = getDocumentTitle(cms,content,location);

      const setScrollPosition = () => {
         // Scroll to an element with the specified ID in the URL hash.
         const hash = location?.hash;
         if (hash) {
            const id = hash.slice(1); // remove # from id
            const domEle = document.getElementById(id);
            if (domEle) {
               scrollWithOffset(domEle, -100); // scroll with offset 100 up
               return;
            }
         }

         // Scroll to the top of the page
         window.scrollTo(0, 0);
      }
   
      setScrollPosition();

   }, [location, cms, content]);
   
   function renderPage() {  
      switch(page){
         case "stories":
            return renderStories();
         case "story":
            return renderStory();
         case "content":
            return renderContent();
         default:
            return renderDefault(); 
      }
   }

   function renderStories() {
      return <Stories
            cms={cms}
            articles={componentList}
         />
   }

   function renderStory() {
     if(componentList.length === 1){
        const componentObj = cms.components[componentList[0]];
        let components = { id: componentList[0], title: content.title, url: content.url, ...componentObj };

        return <React.Fragment>
            {renderMetadata(componentObj, content.title)}
            <Story.Article
               cms={cms}
               components={components}
            />
         </React.Fragment>
     }
   }

   function renderContent() {
      if(componentList.length === 1){
         const componentObj = cms.components[componentList[0]];
         let renderComponents = components => components.map((component, i) =>
            component ?
               <div key={component.type + i}>
                  {getComponent(component)}
               </div> : null
         );
         return <div>
            {renderMetadata(componentObj, content.title)}
            {renderComponents(componentObj.components)}
         </div>
      }
   }

   function renderDefault() {
      return componentList.map((componentId, index) =>
         <div key={componentId + index}>{getComponent(componentId)}</div>
      );
   }

   function renderMetadata(pageData, title) {
      const { metadata, type, components, locales } = pageData;
      let getOgImage = metadata => {
         if (metadata && metadata.ogImage) {
            return getImage(metadata.ogImage, null, true);
         }
     
         // If page has Hero banner with image then set this image to OG Image
         const heroComponent = components?.find(component => component.type === 'heroBanner');
         if (heroComponent) {
            if (heroComponent.image) {
               return getImage(heroComponent.image, null, true);
            } else if (heroComponent.video) {
               return getImage(heroComponent.video, null, true);
            }
         }
      
         return '/resources/images/global/movember_thumbnail.png';
      }

      let newMeta = {
         title: metadata?.metaTitle || title,
         description: metadata?.metaDescription || null,
         ogTitle: metadata?.ogTitle || (metadata?.metaTitle || title),
         ogDescription: metadata?.ogDescription || (metadata?.metaDescription || null),
         ogImage: getOgImage(metadata)
      }

      // Create URL metadata using content.url from the sitemap to avoid incorrect URL in prerender 
      // (e.g., AU Homepage Url  in prerender is https://au.movember.com/index?locale=au, it should be https://au.movember.com/ for SEO)
      const urlMetadata = content.url === '/index' ? `${window.location.origin}/` : `${window.location.origin}${content.url}`;

      const getCanonicalAlternateLinks = (supportedLocales) => {
         let seoLinks = [];
         const urlLocale = getUrlLocale();
         const currentCountry = urlLocale.country || configCountry,
         currentLanguage = urlLocale.language || configLanguage;
         // Canonical Link
         const canonicalHref = currentCountry && currentLanguage ? getUrlFromLocale(currentCountry, currentLanguage) : urlMetadata;
         seoLinks.push(<link rel={'canonical'} href={canonicalHref} />);
         // Alternate links
         if (supportedLocales && supportedLocales.length > 0) {
            supportedLocales.forEach((locale, i) => {
               let formattedLocale = locale.toLowerCase().replace("_", "-");
               let [localeLanguage, localeCountry] = formattedLocale.split('-');
               let url = getUrlFromLocale(localeCountry, localeLanguage);
               let hreflangTag = hreflangStandardCodes[formattedLocale] || formattedLocale;
               if (i === 0) {
                  // If homepage, then set x-default url to en-ex locale
                  let xDefaultUrl = url;
                  if (isHomepage()) {
                     xDefaultUrl = getUrlFromLocale('ex', 'en');
                  }
                  seoLinks.push(<link key={'x-default'} rel={'alternate'} href={xDefaultUrl} hrefLang='x-default' />);
               }
               seoLinks.push(<link key={i} rel={'alternate'} href={url} hrefLang={hreflangTag} />);
            })
         }
         return seoLinks;
      }
     
      return (
         <Helmet>
            <meta name="title" content={newMeta.title} />
            <meta name="description" content={newMeta.description ? newMeta.description : ''} />
            <meta name="url" content={urlMetadata} />
            <meta property="og:title" content={newMeta.ogTitle} />
            <meta property="og:description" content={newMeta.ogDescription ? newMeta.ogDescription : ''} />
            <meta property="og:url" content={urlMetadata} />
            <meta property="og:type" content={type}/>
            <meta property="og:image" content={newMeta.ogImage} />
            { !pageData.isGlobalFallback && getCanonicalAlternateLinks(locales)}
         </Helmet>
      );
   }

   function getDocumentTitle(cms, content, location) {
      let docTitle = "";

      // Below is the behaviour has discussed with the Tom
      // Set meta title if avilable for both Home and dynamic pages
      if(checkNested(content, 'components', 0) && checkNested(cms, 'components', content.components[0], 'metadata', 'metaTitle')){
         docTitle = cms.components[content.components[0]].metadata.metaTitle;
      }

      // set Title from page if mata title is not avilable
      if(!docTitle  && content && content.title){
         docTitle = content.title;
      }

      //fallback to site settings for if ther are any edge cases
      if(!docTitle && checkNested(cms, 'siteSettings', 'metadata', 'title')){
         docTitle = cms.siteSettings.metadata.title;
      }

      return `${docTitle} ${docTitle.length > 0 ? ' - Movember' : 'Movember'}`;
   }

   function getComponent(componentObj) {
      switch (componentObj && componentObj.type && componentObj.type.toLowerCase()) {
         case "herovideo":
            return <Templates.HeroVideo {...componentObj} />
         case 'getinvolved':
            return <Templates.GetInvolved {...componentObj} />
         case 'programcarousel':
            return <Templates.ProgramCarousel {...componentObj} />
         case 'autoplaycarousel':
            return <Templates.AutoplayCarousel {...componentObj}/>
         case 'socialproof':
            return <Templates.SocialProof {...componentObj} />
         case 'marquee':
            return <Templates.Marquee {...componentObj} />
         case 'twocolumnblock':
            return <Templates.Block2Column {...componentObj} />
         case 'richtextblock':
            return <Templates.RichTextBlock {...componentObj} />
         case 'htmlblock':
            return <Templates.HtmlBlock {...componentObj} />
         case 'accordion':
            return <Templates.Accordion {...componentObj} />
         case 'herobanner':
            return <Templates.HeroBanner {...componentObj} />
         case 'pageheader':
            return <Templates.PageHeader {...componentObj} />
         case 'multicolumn':
            return <Templates.MultiColumn {...componentObj} />
         case 'membertally':
            return <Templates.MemberTally {...componentObj} />
         case 'tcamheader':
            return <Templates.TcamHeader {...componentObj} />
         case 'tcamheaderupgrade':
            return <Templates.TcamHeaderUpgrade {...componentObj} />
         case 'testimonial':
            return <Templates.Testimonial {...componentObj} />
         case 'dataform':
            return <Templates.DataForm cms={cms} {...componentObj} />
         case 'tcamstatic':
            return <Templates.TcamStatic {...componentObj} />
         case 'tcamwiggle':
            return <Templates.TcamWiggle {...componentObj} />
         case 'sharedcontent':
            return componentObj.content?.map(item => getComponent(item)) || null;
         default:
            return null;
      }
   }

   return (
      <React.Fragment>
         {renderPage()}
      </React.Fragment>
   );
}
 
export default DynamicPage;