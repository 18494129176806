import { StyledImg, Link } from './Partners.styles';
import { getImage } from 'components/utils/helper';
import { Partner } from './Partners.models'

function PartnerLogo({link, name, logo, isActive }: Partner) {
    return (
        <Link href={link ? link : "#"} target={"_blank"} rel="noreferrer" aria-label="partner link"
            isActive={isActive}>
            <StyledImg
                isActive={isActive}
                src={getImage(logo, 'small')} width="106" height="106" alt={name}>
            </StyledImg>
        </Link>);
}

export default PartnerLogo