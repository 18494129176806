
import React from 'react';
import { Container, Row, Col } from 'themes/MoGrid';
import { Wrapper, PartnerContainer, PartnerRow, TitleUnderline, LogoContainer, StyledImg, PartnerLink, PartnersCopy} from './partners.style';
import { getImage, renderParagraph } from 'components/utils';
import { Link } from 'react-router-dom';


const ImpactHub = ({ cms }) => {

   const pageTitle = cms.partnersSettings.partnersPageTitle
   const pageDescription = cms.partnersSettings.partnersPageDescription
   const standardPartners = cms.partners.filter(item => item.type !== 'dollar_matcher')

   const renderPartners = (partnerList) =>{
     if(partnerList.items.length > 0){
      return(      
         <PartnerRow>
            <TitleUnderline>{partnerList.title}</TitleUnderline>
            <Row>
            
            {partnerList.items.map((item, index) => 
               <Col sm={3} xs={6} key={index}>
                     <LogoContainer>
                        <PartnerLink href={item.link ? item.link : "#"} target={"_blank"} rel="noreferrer" isActive={true}>
                        <StyledImg isActive={true} src={getImage(item.logo, 'small')} name={item.name} alt={item.name}></StyledImg>
                        </PartnerLink>

                     </LogoContainer>
               </Col>
            )}
            </Row>
         </PartnerRow>
      )
     }

   }

   return (
         <Wrapper>
            <PartnerContainer>
                  
                   <Container>
                   <PartnersCopy>
                     <h1>{pageTitle}</h1>
                     <p>{renderParagraph(pageDescription)}</p>
                     <Link to='/friends/dollar-matching'>{cms.partnersSettings.dmPageTitle}</Link>
                   </PartnersCopy>
                    {standardPartners.map(partnerType => renderPartners(partnerType))}


                  </Container> 
            </PartnerContainer>
        </Wrapper>
   );
}
 
export default ImpactHub;
