import React, { useState, useEffect, useContext, useRef } from 'react';
import { Container, StyledHeader, HeaderLogo, Preview, Menu, Nav, NavMenu, NavItem, CTAs, IconsGroup, Icon, IconStart, IconClose,
    SearchBar, SearchForm, SideNavContainer, ButtonsGroup, LoggedIn, User, UserImage, UserMenu, FirstName, LogOut, Login, CampaingButtons, CampaingBtn } from './header.style';
import MainNav from './MainNav/MainNav';
import SideNav from './SideNav/SideNav';
import LanguageBar from './LanguageBar/LanguageBar';
import CookieBar from './CookieBar/CookieBar';
import  { Link, useLocation } from 'react-router-dom';
import DropdownButton from 'components/common/Button/DropdownButton/DropdownButton';
import {ConfigContext} from 'context/AppContext';
import SearchInput from './SearchInput/searchInput';
import { locales } from 'components/utils';
import { getCookie, getUserCookieName, useComponentVisible, IsOnMobile, useClickOutside, checkNested, getLegacyAssetUrl, getBaseUrl, getUrlLocale } from 'components/utils';

const Header = ({cms}) => {
    let config = useContext(ConfigContext);
    let location = useLocation();

    const [headerVisible, setHeaderVisible] = useState(true);
    const [activeMenu, setActiveMenu] = useState(null);
    const [isHoverNav, setIsHoverNav] = useState(false);
    const [searching, setSearching] = useState(false);
    const [loggedIn, setLoggedIn] = useState(getCookie('movember-produs-2016_logged') === '1');
    const [userMenuVisible, setUserMenuVisible] = useState(false);
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const isOnMobile = IsOnMobile();
    const [headerHeight, setHeaderHeight] = useState((isOnMobile && !loggedIn) ? '133px' : '75px');
    const [headerPreview, setHeaderPreview] = useState(false);
    const [cookieEnabled, setCookieEnabled] = useState(false);
    const [siteNav, setSiteNav] = useState(() => {
        const initialNav = cms?.nav?.siteNav;
        return initialNav !== undefined ? initialNav : null;
      });

    const [logoDesktop, setLogoDesktop] = useState('/resources/images/global/movember_logo_primary.svg');
    const [logoMobile, setLogoMobile] = useState('/resources/images/global/movember_logo.png');
    const [logoUrl, setLogoUrl] = useState('/');
    const [loginText, setLoginText] = useState('Login');
    const [loginUrl, setLoginUrl] = useState('/login');
    const [registerText, setRegisterText] = useState('Sign Up');
    const [registerUrl, setRegisterUrl] = useState('/register');
    const [donateText, setDonateText] = useState('Donate');
    const [donateOptions, setDonateOptions] = useState([]);
    const t1CountryCodes = Object.keys(locales).slice(0, 6); // T1 countries code ['au', 'uk', 'ca', 'us', 'ie', 'ca', 'nz']

    // Close user menu when click outside of the menu
    const clickOutsideWrapperRef = useRef(null);
    useClickOutside(clickOutsideWrapperRef, () => setUserMenuVisible(false));



    useEffect(() => {  
        // Hide header navigation for stories embed mode (to show on mobile native app)
        if((location.pathname.indexOf('/story') >= 0) && location.search === '?layout=embed'){
            setHeaderVisible(false);
        }

        // Show header in preview mode
        if(getCookie('cms-login-cookie') === 'true' && location.hash === '#preview'){
            setHeaderPreview(true);
        }
    }, [location]);

    useEffect(() => {
        // If Cookie is enabled and Onetrust cookie isn't enabled, show Cookie bar.
        if (config.getValue("app.tracking.cookies.enabled") && !config.getValue("app.tracking.cookies.onetrust.enabled") === "1") {
          setCookieEnabled(true);
        }
    }, [config]);

    useEffect(() => {
        // Set value for logo and campaign buttons
        if (checkNested(cms, 'pages', 'content', 'url')){
            setLogoUrl(cms.pages.content.url)
        }
        if(checkNested(cms, 'siteSettings', 'logoDesktop', 'url')) {
            setLogoDesktop(cms.siteSettings.logoDesktop.url)
        }
        if(checkNested(cms, 'siteSettings', 'logoMobile', 'url')) {
            setLogoMobile(cms.siteSettings.logoMobile.url);
        }
        if(checkNested(cms, 'siteSettings', 'campaignButtons', 'login')){
            setLoginText(cms.siteSettings.campaignButtons.login.text);
            setLoginUrl(cms.siteSettings.campaignButtons.login.url);
        }
        if(checkNested(cms, 'siteSettings', 'campaignButtons', 'register')){
            setRegisterText(cms.siteSettings.campaignButtons.register.text);
            setRegisterUrl(cms.siteSettings.campaignButtons.register.url);
        }
        if(checkNested(cms, 'siteSettings', 'campaignButtons', 'donate')){
            if(checkNested(cms, 'siteSettings', 'campaignButtons', 'donate', 'donateBtn')){
                setDonateText(cms.siteSettings.campaignButtons.donate.donateBtn.text);
            }
            let optionsList = [];
            if(checkNested(cms, 'siteSettings', 'campaignButtons', 'donate', 'giveToAPersonBtn')){
                optionsList.push({
                    text: cms.siteSettings.campaignButtons.donate.giveToAPersonBtn.text,
                    url: cms.siteSettings.campaignButtons.donate.giveToAPersonBtn.url
                });
            }
            if(checkNested(cms, 'siteSettings', 'campaignButtons', 'donate', 'giveOnceBtn')){
                optionsList.push({
                    text: cms.siteSettings.campaignButtons.donate.giveOnceBtn.text,
                    url: cms.siteSettings.campaignButtons.donate.giveOnceBtn.url
                });
            }
            if(checkNested(cms, 'siteSettings', 'campaignButtons', 'donate', 'giveMonthlyBtn') && config.getValue('app.site.donation.recurring.enabled') === '1'){
                optionsList.push({
                    text: cms.siteSettings.campaignButtons.donate.giveMonthlyBtn.text,
                    url: cms.siteSettings.campaignButtons.donate.giveMonthlyBtn.url
                });
            }
            setDonateOptions(optionsList);
        }

        // DOT-294: Remove Stories in Navigation Menu in T2 countries
        setSiteNav((prevSiteNav) => {
            const updatedSiteNav = { ...prevSiteNav };
            const isT1Country = t1CountryCodes.includes(getCurrentCountryCode());
            if(!isT1Country) {
                delete updatedSiteNav.stories;
            }
            return updatedSiteNav;
        });
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cms]);

    useEffect(() => {
        if(getCookie(getUserCookieName('logged')) === '1'){
            setLoggedIn(true);
            // Header default height
            setHeaderHeight('75px'); 
        }
    }, [loggedIn]);

    function loggingOut() {
        fetch(`${getLegacyAssetUrl('api', '/auth', config.getCountry() )}`, {method: 'DELETE'})
        .then(res => res.json())
        .then(
            success => {
                if(success.result){
                    setLoggedIn(false);
                    // Header height on mobile
                    setHeaderHeight(isOnMobile  ? '138px' : '75px');
                }
            },
            error => {
                console.log("Request error " + error);
            }
        ); 
    };

    function getUserInfo(info) {
        switch (info) {
            default:
            case 'firstname':
                let fullname = getCookie(getUserCookieName('name'));
                return fullname ? fullname.split('+')[0] : '';
            case 'image':
                let imageObj = decodeURIComponent(getCookie(getUserCookieName('pic')));
                return imageObj ? imageObj : null;
        }
    }

    const getCurrentCountryCode = () => {
        const urlLocale = getUrlLocale();
        const countryCode = urlLocale?.country || config.getCountry();
        return countryCode;
    };

    
  
    return (
        headerVisible ?
        <Container height={headerHeight}>
            <StyledHeader>
                <HeaderLogo logo={logoDesktop} logoMobile={logoMobile} preview={headerPreview}>
                    {headerPreview && <Preview>PREVIEW</Preview>}
                    <Link to={logoUrl} aria-label="header logo"></Link>
                </HeaderLogo>
                <Menu>
                    <CTAs>
                        <IconsGroup>
                            <Icon isSideMenu={true}>
                                <IconStart isVisible={!isComponentVisible} isSideMenu={true} onClick={()=> setIsComponentVisible(true)}/>
                                <IconClose isVisible={isComponentVisible} onClick={()=> setIsComponentVisible(false)}/>
                                 
                            </Icon>
                            <Icon IconStart>
                                <IconStart isVisible={!searching} onClick={() => setSearching(true)}/>
                                <IconClose isVisible={searching} onClick={() => setSearching(false)}/>
                            </Icon>
                        </IconsGroup>
                        <ButtonsGroup>
                            {loggedIn ? 
                            <LoggedIn>
                                <User onClick={() => setUserMenuVisible(!userMenuVisible)}>
                                    <FirstName>{getUserInfo('firstname')}</FirstName>
                                    <UserImage>
                                        <img  src={getUserInfo('image') ? getBaseUrl(config.getCountry()) + getUserInfo('image') : null} alt="user-profile"/>
                                    </UserImage>
                                    <UserMenu isVisible={userMenuVisible} ref={clickOutsideWrapperRef}>
                                        { (cms.nav && cms.nav.loggedInMenu) &&
                                            <ul>
                                                { 
                                                    Object.keys(cms.nav.loggedInMenu).map(item => 
                                                    <React.Fragment key={item}>
                                                        {
                                                         item === 'logOut' ?
                                                         // eslint-disable-next-line
                                                         <LogOut><a onClick={()=> loggingOut()}>{cms.nav.loggedInMenu[item].text}</a></LogOut>
                                                         :<li><a href={cms.nav.loggedInMenu[item].link}>{cms.nav.loggedInMenu[item].text}</a></li>
                                                        }
                                                    </React.Fragment>
                                                )}
                                            </ul>
                                        }
                                    </UserMenu>
                                </User>
                            </LoggedIn>
                            : <Login href={loginUrl}>{loginText}</Login>}
                            { !(loggedIn && isOnMobile) &&
                                <CampaingButtons>
                                    {!loggedIn && <CampaingBtn href={registerUrl}>{registerText}</CampaingBtn>}
                                    <DropdownButton text={donateText} options={donateOptions} />
                                </CampaingButtons>
                            }
                        </ButtonsGroup>
                    </CTAs>
                    <Nav>
                        <NavMenu>
                            { typeof siteNav === 'object' &&
                                Object.keys(siteNav).map(menu =>
                                        <React.Fragment key={menu}>
                                            <NavItem>
                                                {/* eslint-disable-next-line */}
                                                <a href="#" onMouseEnter={() => { setActiveMenu(menu !== 'stories' ? siteNav[menu].categories : siteNav[menu]); setIsHoverNav(true) }}
                                                    onMouseLeave={() => setIsHoverNav(false)}>
                                                    {siteNav[menu].title}
                                                </a>
                                            </NavItem>
                                        </React.Fragment>
                                    )}
                        </NavMenu>
                        <MainNav cms={cms}  activeMenu={activeMenu} isHoverNav={isHoverNav}/>
                    </Nav>
                </Menu>
            </StyledHeader>

            {typeof siteNav === 'object' &&
                <SideNavContainer ref={ref}>
                    <SideNav 
                        isVisible={isComponentVisible} 
                        cms={cms}
                        nav={siteNav} 
                        loggedIn={loggedIn} 
                        headerHeight={headerHeight}
                        onClose={() => setIsComponentVisible(false)}/>
                </SideNavContainer>
            }

            <SearchBar isVisible={searching} headerHeight={headerHeight}>
                <SearchForm>
                    <SearchInput placeholder={cms.snippets['text-common-search'].text}/>
                </SearchForm>
            </SearchBar>
            <LanguageBar/>
            {cookieEnabled && <CookieBar cms={cms} loggedIn={loggedIn} />}
        </Container>
        : null
    );
}

export default Header;
