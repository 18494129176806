import React, { useState } from 'react';
import  { Link } from 'react-router-dom';
import { Container, Content, Column, Heading, Item, Title, Description, StoryMenu, StoryItem } from './MainNav.style';

const MainNav = ({cms, activeMenu, isHoverNav}) => {
    const [isHoverMenu, setIsHoverMenu] = useState(false);
    return (
        <Container isVisible={isHoverNav || isHoverMenu} onMouseEnter={()=> setIsHoverMenu(true)} onMouseLeave={()=> setIsHoverMenu(false)}>
            <Content>
                { Array.isArray(activeMenu)
                    ? activeMenu.map((category, i) =>
                        <Column key={i}>
                            <Heading>{category.text}</Heading>
                            {category.navItems.map((ele, i) =>
                                <React.Fragment key={i}>
                                    { ele.header &&
                                        <Item href={ele.url}>
                                            <Title>{ele.title}<i className="fas fa-chevron-right"/></Title>
                                            <Description>{ele.description}</Description>
                                        </Item>
                                    }
                                </React.Fragment>
                            )}
                        </Column>
                    )
                    : <Column> {/** For STORIES menu */}
                        <StoryMenu>
                            {activeMenu && activeMenu.primary &&
                                <StoryItem isPrimary={true}>
                                    {activeMenu.primary.map((item,i) =>
                                        <Link key={i} to={item.url}><span>{item.title}</span></Link>
                                    )}
                                </StoryItem> 
                            }
                             {activeMenu && activeMenu.secondary &&
                                <div>
                                    {activeMenu.secondary.map((item,i) =>
                                        <StoryItem key={i}> 
                                            <Link to={item.url}><span>{item.title}</span></Link>
                                        </StoryItem>
                                    )}
                                </div> 
                            }
                        </StoryMenu>
                      
                    </Column>
                }
            </Content>
        </Container>
    );
}
 
export default MainNav;
