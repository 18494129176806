import FileDownloadLineIcon from 'remixicon-react/FileDownloadLineIcon';
import LinkIcon from 'remixicon-react/LinkIcon';
import ArrowRightLineIcon from 'remixicon-react/ArrowRightLineIcon';
import ArrowRightUpLineIcon from 'remixicon-react/ArrowRightUpLineIcon';
import { StyledButton, Type, ArrowCurl, ArrowCurlUp, ArrowCurlDown } from './Button.style';
import Text from '../Text/Text';
import { getAssetUrl, getUrlLocale } from 'components/utils';

interface ButtonFile {
  formats: string[];
  id: string;
  name: string;
  url: string;
  originalFilename: string;
}

interface Props {
  btnType: Type;
  btnText: string;
  btnLink?: string;
  isDisabled?: boolean;
  isDynamicBtn?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
  btnFile?: ButtonFile;
  dataAttributes?: object; // Pass any attribute you want to add to the button e.g. { data-attribute="button", data-value="value" }
}

const iconType = (type: Type) => {
  switch (type) {
    case 'external':
      return <ArrowRightUpLineIcon />;
    case 'download':
      return <FileDownloadLineIcon />;
    case 'link':
      return <LinkIcon />;
    case 'arrow':
      return <ArrowRightLineIcon />;
    case 'secondaryArrow':
      return <ArrowRightLineIcon />;
    case 'tcam':
      return <ArrowCurl />;
    case 'tcamUp':
      return <ArrowCurlUp />;
    case 'tcamDown':
      return <ArrowCurlDown />;
    default:
      return null;
  }
};

const Button: React.FC<Props> = ({
  btnText,
  btnLink,
  btnType = 'secondary',
  isDisabled = false,
  isDynamicBtn = false,
  btnFile = { url: undefined, name: '', id: '', formats: [], originalFilename: '' },
  onClick,
  dataAttributes
}) => {
  const { country, language } = getUrlLocale() as { country: string, language: string };
  const hasIcon = btnType === 'download' || btnType === 'link' || btnType === 'arrow';
  const downloadURL = btnFile?.name ? `${getAssetUrl('file', country, language)}${btnFile?.id}.${btnFile?.formats[0]}` : undefined;
  const href = btnType === 'download' ? downloadURL : btnLink;
  return (
    <StyledButton
      data-attribute="button"
      onClick={onClick}
      href={href}
      type={btnType}
      isDisabled={isDisabled}
      isDynamicBtn={isDynamicBtn}
      {...(btnType === 'external' ? { target: '_blank' } : '')}
      {...(btnType === 'download' ? { download: btnFile?.originalFilename } : '')} 
      {...dataAttributes}     
    >
      <Text marginRight={hasIcon ? '0.5rem' : '0'} size={hasIcon ? '1rem' : undefined}>
        {btnText} 
      </Text>
      {iconType(btnType)} 
    </StyledButton>
  );
};

export default Button;