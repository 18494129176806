import { useState, useEffect, useRef } from 'react';
import { renderParagraph } from 'components/utils/render';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import { Section, SubHeading, Icon, Content } from './Accordion.style';

interface Props {
    index: number;
    subHeading: string;
    content: any;
}

const AccordionItem: React.FC<Props> = ({index, subHeading, content}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [height, setHeight] = useState(0);
    const ref = useRef<any>(null);
    const toggleAccordion = () => { 
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        setHeight(ref.current.scrollHeight);
    },[]);
    
    return (
        <Section firstItem={index===0}>
            <SubHeading onClick={() => toggleAccordion()}>
                {subHeading}
                <Icon isOpen={isOpen}><ArrowDownSLineIcon /></Icon>
            </SubHeading>
            <Content ref={ref} isOpen={isOpen} height={height}>{renderParagraph(content)}</Content>
        </Section>
    );
};

export default AccordionItem;