import React, { useState, useContext, useEffect } from 'react';
import { CMSContext } from "../../../context/AppContext";
import { getUrlLocale, getLegacyAssetUrl } from 'components/utils';
import { RichTextBlock } from 'components/content/templates';
import ErrorBox from './NoRoute.style';

const NoRoute = () => {
  const cms = useContext(CMSContext);
  const [error, setError] = useState();
  const [showMessage, setShowMessage] = useState(false);
  let path = window.location.pathname;
  let message = `404: Page not found: ${path}`;
  const urlLocale = getUrlLocale();
  const currentLocaleLanguage = urlLocale.language;
  const currentUrlCountry = urlLocale.country;
  const regex = new RegExp(`(/${currentLocaleLanguage}/story/|/story/)`, "g");
  const isStoryPage = path.match(regex);
  const [isOnPreview, setIsOnPreview] = useState(false);
  const [isLocaleLanguage, setIsLocaleLanguage] = useState(false);

  useEffect(() => {
    let cmsContainer = document.getElementById('cms-login-container');
    if(cmsContainer.childElementCount > 0){
      setIsOnPreview(true);
    }
  },[]);
  
  useEffect(() => {
    if(isStoryPage){
    let url = getLegacyAssetUrl('json', `resources/json/global`, currentUrlCountry);
      if (path.includes(`/${currentLocaleLanguage}/`)) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        path = path.replace(`/${currentLocaleLanguage}`, '');
        setIsLocaleLanguage(true);
      }

      if (!url.includes(path)) {
        // Update the url with the correct path
        url = `${url}${path}`;
      }
      
      // Get the global story
      fetch(url)
        .then((res) => res.json())
        .then(
          result => {
            if(result) {
              // set global fallback property to the return story
              const storyId = result.pages?.stories?.components?.[0]?.id;
              if (storyId && result.components) {
                result.components[storyId].isGlobalFallback = true;
              }
              // if the language is present we need to add it back in 
              if (isLocaleLanguage) {
                result['preRenderUrl'][0] = `/${currentLocaleLanguage}${result['preRenderUrl'][0]}`;
                result['pages']['stories']['components'][0]['url'] = `/${currentLocaleLanguage}${result['pages']['stories']['components'][0]['url']}`;
              }
              addNewStoryToCms(result);
            }
          },
          error => {
            setShowMessage(true);
            console.error(error.toString());
            setError('There was an error with the page');
          }
        );
    } else {
      setShowMessage(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [isLocaleLanguage]);


  // Check if a story page

  function addNewStoryToCms(story){
    let newCms = {...cms}; // Must be new object otherwise MoRouter won't re-render
    // Set global story to current cms sitemap
    if(story.preRenderUrl && story.pages && story.components){
      newCms.preRenderUrl.push(story.preRenderUrl[0]);
      newCms.pages.stories.components.push(story.pages.stories.components[0]);
      newCms.components[story.pages.stories.components[0].id] = story.components[story.pages.stories.components[0].id];
      // Dispatch change cms event
      window.dispatchEvent(new CustomEvent('changeCms', { detail: {cms: newCms} }));
    }
  }
  return (
    <React.Fragment>
      {isOnPreview ? 
      <div>
        &nbsp;&nbsp; Loading preview for <strong>{path}</strong>
        &nbsp;&nbsp;<span className="fas fa-sync-alt fa-spin"></span>
      </div>
      : <ErrorBox>
        <img src='/resources/images/icons/movember_logo_primary.svg' alt='movember' />
        <h2>{showMessage && <div>{message}</div>}</h2>
        <h3>{error && <div>{error}</div>}</h3>
        <RichTextBlock richTextBlock={cms.snippets["block-404-message"] ? cms.snippets["block-404-message"].block : null} />
      </ErrorBox>
      }
    </React.Fragment>
    
  
  );
}

export default NoRoute;
