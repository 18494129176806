import { useWindowWidth } from './hooks';
import { breakpointsInt } from 'themes/breakpoints.theme';
import { locales, cloudFrontUrls } from './const';

export const IsOnMobile = () => {
  return useWindowWidth() < breakpointsInt.sm;
}

export const isTouchDevice = () => {
  let hasTouchScreen = false;
if ('maxTouchPoints' in navigator) {
    hasTouchScreen = navigator.maxTouchPoints > 0;
  } else if ('msMaxTouchPoints' in navigator) {
    hasTouchScreen = navigator.msMaxTouchPoints > 0;
  } else {
    const mQ = matchMedia('(pointer:coarse)');
    if (mQ && mQ.media === '(pointer:coarse)') {
      hasTouchScreen = !!mQ.matches;
    } else if ('orientation' in window) {
      hasTouchScreen = true; // deprecated, but good fallback
    } else {
      // Only as a last resort, fall back to user agent sniffing
      const UA = navigator.userAgent;
      hasTouchScreen =
        /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) ||
        /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    }
  }
  return hasTouchScreen;
}

export const isHomepage = () => {
  // Homepage url example: https://ca.movember.com/ or https://ca.movember.com/fr or https://ca.movember.com/fr/
  const pathname = window?.location?.pathname;
  return pathname === '/' || pathname.length === 3 || pathname.length === 4 && pathname.endsWith('/');
}

export const getEnvironment = () =>{
  let hostName = window.location.hostname;
  if (hostName.indexOf('localhost') > -1 || hostName.indexOf('dev.movember.com') > -1) {
    return 'local';
  } else if (hostName.indexOf('uat.movember.com') > -1) {
    return 'uat';
  } else {
    return 'prod';
  }
}

export const getBaseUrl = (country) => {
  const baseCountry = country || 'au';
  if (getEnvironment() === 'local') {
    return `https://${baseCountry}.uat.movember.com/`;
  }
  return window.location.origin.concat('/');
}

/**
 * Returns the URL for a specific type of asset based on the country and language.
 *
 * @param {string} type - The type of asset ('image', 'file', or 'cms').
 * @param {string} country - The country code for the asset URL (default is 'au').
 * @param {string} language - The language code for the asset URL.
 * @return {string} The URL of the asset.
 */
export const getAssetUrl = (type, country, language) => {
  const baseCountry = country || 'au';
  const isLocal = getEnvironment() === 'local';
  const domain = `https://${baseCountry}.uat.movember.com`;
  const imageEndpoint = '/uploads/images/resources/';
  const fileEndpoint = '/uploads/images/resources/files/';
  const cmsEndpoint = (language && language.length > 1) ? `/${language}/sitemap.json` : '/sitemap.json';

  switch (type) {
    case 'image':
      return isLocal ? `${domain}${imageEndpoint}` : imageEndpoint;
    case 'file':
      return isLocal ? `${domain}${fileEndpoint}` : fileEndpoint;
    case 'cms':
    default:
      return isLocal ? `${domain}${cmsEndpoint}` : cmsEndpoint;
  }
}

// Get href url bases on country and language
export const getUrlFromLocale = (country, language) => {
  const environment = getEnvironment();
  const env = environment === 'prod' ? 'movember.com' : 'uat.movember.com';
  const currentPathName = window.location.pathname;
  const urlLanguage = `/${language}`;
  const countryLocale = locales?.[country]?.language;
  const isDefaultLanguage = countryLocale?.some(locale => locale.default && locale.code === language);
  
  // Get URL path without language
  let urlWithoutLanguage = (currentPathName.charAt(3) === '/') ? currentPathName.substring(3) : ( currentPathName.length === 3 ? '/' : currentPathName);
  // If homepage URL, remove index
  if (urlWithoutLanguage === '/index') {
    urlWithoutLanguage = '/';
  }

  return `https://${country}.${env}${isDefaultLanguage ? '' : urlLanguage}${urlWithoutLanguage}`;
}

export const getConfigUrl = (country) =>{
  let baseCountry  = country || 'au';
  if (getEnvironment() === 'local') {
    return `https://${baseCountry}.uat.movember.com/resources/json/${baseCountry}/config.json`;
  } else {
    return `/resources/json/${baseCountry}/config.json`;
  }
}

/**
 * This was built initially to be able to render images from the legacy site, given the relative path
 * to the image on the legacy site, which will be appended to the legacy website domain matching the current
 * environment.
 *
 * @todo - handle locales (ie. https://ca.movember.com/fr/IMAGE)
 * @todo - auto-detect country if not specifically passed?
 *
 * @param type
 * @param path
 * @param country
 * @returns {*}
 */
export const getLegacyAssetUrl = (type, path, country) => {
  let baseUrl = null;
  let baseCountry = country || 'au'; // @todo, auto-detect default country?
  path = path.replace(/^\//, '');

  switch (type) {
    case 'image':
      switch (getEnvironment()) {
        case 'local':
          baseUrl = `https://${baseCountry}.uat.movember.com/`;
          break;
        case 'prod':
          baseUrl = `https://${baseCountry}.movember.com/`;
          break;
        default:
          baseUrl = `https://${baseCountry}.` + getEnvironment() + `.movember.com/`;
          break;
      }
      break;
    case 'api':
      baseUrl = `https://${
        baseCountry + (getEnvironment() === 'prod' ? '' : '.uat')
      }.movember.com/api/v18/`;
      break;
    case 'json':
      if (getEnvironment() === 'local') {
        baseUrl = `https://${baseCountry}.uat.movember.com/`;
      } else {
        baseUrl = window.location.origin.concat('/');
      }
      break;
    default:
      baseUrl = window.location.origin.concat('/');
  }
  return baseUrl + path;
}

export const getWordsPerMin = (country) =>{
  switch (country) {
    case 'au':
    case 'us':
    case 'uk':
    case 'ca':
    case 'nz':
      return 250;
    case 'de':
      return 150;
    default:
      return 250;
  }
}

// Cookies get/set
export const setCookie = (cname, cvalue, exdays, hasDomain) => {
  const d = new Date();
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  const domain = hasDomain
    ? `domain=${
        getEnvironment() === 'local'
          ? '.localhost:3000'
          : getEnvironment() === 'uat'
          ? '.uat.movember.com;'
          : '.movember.com;'
      }`
    : '';

  document.cookie = `${cname}=${cvalue};${domain}${expires};path=/`;
}

export const getCookie = (cname) => {
  var name = cname + '=';
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

export const getUserCookieName = (postfix) => {
  let cookieEnvironment = getEnvironment();

  // Our cookie names still use "produs" as the env for Production, not "prod"
  if (cookieEnvironment === "prod") {
    cookieEnvironment = "produs";
  }

  return `movember-${cookieEnvironment}-2016_${postfix}`;
}

export const getImage = (item, size, isOriginal) =>{
  const prefix = getAssetUrl('image');
  let postfix = '';
  if (
    item &&
    item.id &&
    item.sizes &&
    item.sizes.length > 0 &&
    item.formats &&
    item.formats.length > 0
  ) {
    // IE doesn't support webp images
    // Don't use webp as original image
    let formatIndex = 0,
      sizeIndex = 0,
      webpIndex = item.formats.indexOf('webp');
    if (webpIndex >= 0) {
      if (isOriginal) {
        // get non-webp format index
        for (let i = 0; i < item.formats.length; i++) {
          if (i !== webpIndex) {
            formatIndex = i;
            break;
          }
        }
      } else {
        formatIndex = webpIndex;
      }
    }

    sizeIndex = item.sizes.indexOf(size) >= 0 ? item.sizes.indexOf(size) : 0;
    postfix = `${item.id}-${item.sizes[sizeIndex]}.${item.formats[formatIndex]}`;
  } else {
    return item ? item.url : null;
  }
  return prefix + postfix;
}

export const getApiUrl = (postfix, country, type) => {
  const version = 'v22';
  let env = getEnvironment() === 'prod' ? '' : 'uat.';
  switch (type) {
    case 'team-target':
      return `https://${country}.${env}movember.com/api/${version}/team-target?team_id=${postfix}`;
    case 'network-target':
      return `https://${country}.${env}movember.com/api/${version}/network-target?network_id=${postfix}`;
    default:
      return `https://${country}.${env}movember.com/api/${version}${postfix}`;
  }
}

/**
 * Retrieves the locale information from the current URL.
 *
 * @return {Object} An object containing the country and language codes extracted from the URL.
 */
export const getUrlLocale = () => {
  let locale = { country: '', language: ''};
  let countryCode = null;
  const { pathname, hostname } = window.location;
  
  //Check if url cointains country code (ex: ca.movember.com)
  if (hostname.charAt(2) === '.') {
    locale.country = countryCode = hostname.substring(0, 2);
  } else {
    // If we don't see a country code in the URL, check cloudFrontUrls
    const cloudFrontKey = Object.keys(cloudFrontUrls).find(key => key === hostname);
    if(cloudFrontKey) {
      locale.country = countryCode = cloudFrontUrls[cloudFrontKey];
    }
  }

  const supportedLanguages = Object.values(locales).map(subdomain => subdomain.language.flatMap(flat => flat)).flatMap(flat => flat).map(lang => lang.code).filter((val, idx , arr) => idx === arr.indexOf(val))
  const langFromURL = pathname.substring(1, 3);
  // even if it is two letters, need to check if its valid
  const isValidLanguage = supportedLanguages.includes(langFromURL)

  // Check if url cointains language code (ex: ca.movember.com/fr/ or ca.movember.com/fr)
  if ((pathname.charAt(3) === '/' || pathname.length === 3) && isValidLanguage) {
    locale.language = langFromURL;
  } else if (countryCode) {
    // If we don't see a language code in the URL, extract the "default" locale for the country
    let defaultLanguage = locales[locale.country]?.language.filter((element) => {return element.default === true})?.[0]?.code;
    if (defaultLanguage) {
      locale.language = defaultLanguage;
    }
  }

  return locale;
}

export const getCurrentCountryCode = () => {
  const urlLocale = getUrlLocale();
  const cloudFrontKey = Object.keys(cloudFrontUrls).find(key => key === window.location.hostname);
  const cloudFrontCountryCode = cloudFrontKey ? cloudFrontUrls[cloudFrontKey] : '';
  const countryCode = urlLocale?.country || cloudFrontCountryCode;
  return countryCode;
}

/**
 * Attempt to determine the current locale as a string (format xx_YY)
 * assuming we can first detect the country code, and the language code
 *
 * @todo Don't use getUrlLocale() for the country/language detection, as it won't
 * convert 'uk' to 'gb', as it preserves 'uk' for use in building URLs...
 * We should have a better more concise function for determining exact Language/Country/Locale
 *
 * ie.
 *  - country code: ca
 *  - language code: fr
 *  - separator: blank
 *   => localeString: `fr_CA`
 *
 *  - country code: ca
 *  - language code: fr
 *  - separator: "-"
 *   => localeString: `fr-CA`
 *
 * @param {string} separator - The string to separate the country code and language code by (default: "_")
 * @returns {string}
 */
export const getCurrentLocaleString = (separator = "_") => {
  let locale = getUrlLocale();
  let localeString = '';

  // Format: fr_CA or fr-CA
  if (locale.country && locale.language) {
    if (locale.country === "uk") {
      locale.country = "gb";
    }
    localeString = locale.language + separator + locale.country.toUpperCase();
  }
  return localeString;
}

/**
 *
 * getUrlParam
 *
 * @param paramId the parameter name
 * @param additionalAllowedCharacters additional characters that we consider valid when looking for a parameter value in the URL.
 *                                    As a general rule, only letters, numbers, '_', '-', '+', '%' and '.' are valid.
 *                                    If we want to consider any other valid characters (e.g. '@'), we have to include them here.
 */
export const getUrlParam = (paramId, additionalAllowedCharacters) => {
  var _chars = additionalAllowedCharacters || '';

  // Get the parameter string; note: for security reasons only certain characters are matched
  // eslint-disable-next-line
  var paramString = window.location.href.match(
    new RegExp(paramId + '=[-a-zA-Z0-9' + _chars + '_+%.]*', 'g'),
  ); // '_', '+', '%' and '.' characters added
  // Return the result:
  return paramString ? paramString[0].replace(paramId + '=', '') : null;
}

export const getDateFormat = (date, country) => {
  switch (country) {
    case 'us':
    case 'ca':
      return date;
    default:
      break;
  }
  return date;
}

export const copyToClipBoard = (value) => {
  if (navigator.userAgent.indexOf('Firefox') >= 0) {
    navigator.clipboard.writeText(value);
  } else {
    if (!document.queryCommandSupported || !document.queryCommandSupported('copy')) {
      return;
    }
    const clipboardContainer = document.getElementById('clipboard-container');
    setTimeout(function () {
      const input = document.createElement('input');
      input.setAttribute('readonly', 'readonly');
      input.setAttribute('value', value);
      clipboardContainer.appendChild(input);

      input.select();
      input.setSelectionRange(0, input.value.length);

      document.execCommand('copy');
      clipboardContainer.removeChild(input);
    }, 500);
  }
};

export const checkNested = (obj, level1, ...deeperLevels) => {
  if (!obj) return false;
  if (deeperLevels.length === 0 && obj.hasOwnProperty(level1)) return true;
  return checkNested(obj[level1], ...deeperLevels);
}

export const formatVideoUrl = (url) => {
  if (!url) return;
  if (url.indexOf('vimeo.com') >= 0) {
    return `${url.replace('vimeo.com', 'player.vimeo.com/video')}?autoplay=1`;
  }
  return `${url.replace('/watch?v=', '/embed/')}?autoplay=1&rel=0&controls=1&enablejsapi=1&origin=${
    window.location.origin
  }`;
}

export const scrollWithOffset = (domElement, offset = -180) => {
  if (domElement) {
    const yCoordinate = domElement.getBoundingClientRect().top + window.scrollY;
    const yOffset = offset;
    window.scroll({ top: yCoordinate + yOffset });
  }
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

export const isValidEmailAddress = (email) => {
  // eslint-disable-next-line
  var regex = /^([A-Za-z0-9_\-\.\+'])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,63})$/;
  return regex.test(email);
};


export const isClient = typeof window !== 'undefined';

export const isServer = !isClient;